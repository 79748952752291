import React from "react"
import { graphql } from "gatsby"

// Hooks & Querys
import { StandardPageFragment } from "../GraphQl/queryFragments" // eslint-disable-line

// Components
import SEO from "../components/global/seo"
import { BlockZone } from "../components/pageBlocks"

const StandardPage = ({ data }) => {
  const { title, metaDescription, keywords, pageBlocks } =
    data.sanityStandardPage || {}
  return (
    <>
      <SEO title={title} keywords={keywords} description={metaDescription} />
      <BlockZone blocks={pageBlocks} />
    </>
  )
}

export default StandardPage

export const pageQuery = graphql`
  query standardPageQuery($slug: String!) {
    sanityStandardPage(slug: { current: { eq: $slug } }) {
      ...StandardPageFields
    }
  }
`
